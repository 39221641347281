import { Head, usePage, Link } from '@inertiajs/react';
import MainLayout from '@/Layouts/MainLayout';
import EventCard from '@/Components/Cards/EventCard';
import ChampionshipCard from '@/Components/Cards/ChampionshipCard';
import CommunityCard from '@/Components/Cards/CommunityCard';
import GameCard from '@/Components/Cards/GameCard';
import HomeSearch from '@/Components/HomeSearch';
import PostCard from '@/Components/Cards/PostCard';

const Home = (props) => {
	const { events, championships, communities, games, posts } = usePage().props;

	return (
		<>
			<Head title="Home" />

			<div className="container mx-auto text-center mt-4 sm:mt-8 px-4">
				<p className="text-primary text-xl font-bold">
					Empowering Players, <br />
					Strengthening Communities.
				</p>
				<h1 className="hidden">OnlyRacing</h1>
				<img className="mt-4 mx-auto max-h-[45px]" src="/images/logo_onlyracing_lg.png" />
				<p className="text-lg mt-4 mx-auto hidden sm:block md:max-w-[50vw]">
					Discover your perfect community and engage in championships and events tailored to your racing preferences. Alternatively, establish your own community to oversee and organize customized championships and events.
				</p>
			</div>

			<div className="container mx-auto mt-8 sm:mt-12 mb-20 px-4">
				<HomeSearch />
			</div>

			<section className="container mx-auto px-4 my-12">
				<div className="divider mb-12">
					<h2>Upcoming Championships</h2>
				</div>
				<div className="flex w-full grid grid-cols-1 md:grid-cols-2 lg:md:grid-cols-3 2xl:grid-cols-4 gap-6 mb-8">
					{championships.map((championship) => (
						<ChampionshipCard
							key={`championship-${championship.id}`}
							championship={championship}
						/>
					))}
				</div>
				<div className="flex justify-center">
					<Link className={`btn btn-sm btn-ghost`} href={route('championships')}>
						<span>View all championships</span>
					</Link>
				</div>
			</section>

			<section className="container mx-auto px-4 my-12">
				<div className="divider mb-12">
					<h2>Featured Games</h2>
				</div>
				<div className="flex w-full grid grid-cols-2 md:grid-cols-3 lg:md:grid-cols-4 2xl:grid-cols-6 gap-6 mb-8">
					{games.map((game) => (
						<GameCard key={`game-${game.id}`} game={game} />
					))}
				</div>
				<div className="flex justify-center">
					<Link className={`btn btn-sm btn-ghost`} href={route('games')}>
						<span>View all games</span>
					</Link>
				</div>
			</section>

			<section className="bg-base-300 my-8 mb-20 bg-flag-lg bg-left-top border-y-2 border-white/[.1]">
				<div className="container mx-auto w-full flex flex-col md:flex-row px-4 py-16">
					<div className="w-full md:w-3/5 flex flex-col self-center md:pr-16 mb-8 md:mb-0">
						<h2 className="text-primary mb-8">It's easy to get involved!</h2>
						<p className="text-xl mb-8">
							Join the Only Racing community by simply logging in through your Discord
							account. It’s easy and FREE. In a few minutes you could…
						</p>
						<ul className="text-xl mb-8">
							<li>
								<span className="material-icons-outlined text-primary mr-2 text-xl">
									done
								</span>
								Create you own sim racing community
							</li>
							<li>
								<span className="material-icons-outlined text-primary mr-2 text-xl">
									done
								</span>
								Create your own championships or events
							</li>
							<li>
								<span className="material-icons-outlined text-primary mr-2 text-xl">
									done
								</span>
								Join other communities and races
							</li>
							<li>
								<span className="material-icons-outlined text-primary mr-2 text-xl">
									done
								</span>
								Tailor your profile, monitor your racing stats
							</li>
						</ul>
						<a href={route('register')} className="btn btn-primary lg:max-w-[70%]">
							Create an account now!
						</a>
					</div>
					<div className="w-full md:w-2/5">
						<img src="/images/home_features.png" />
					</div>
				</div>
			</section>

			<section className="container mx-auto px-4 my-12">
				<div className="divider mb-12">
					<h2>Upcoming Events</h2>
				</div>
				<div className="flex w-full grid grid-cols-1 md:grid-cols-2 lg:md:grid-cols-3 2xl:grid-cols-4 gap-6 mb-8">
					{events.map((event) => (
						<EventCard key={`event-${event.id}`} event={event} />
					))}
				</div>
				<div className="flex justify-center">
					<Link className={`btn btn-sm btn-ghost`} href={route('events')}>
						<span>View all events</span>
					</Link>
				</div>
			</section>

			<section className="bg-base-300 my-8 mb-20 bg-flag-lg bg-left-top border-y-2 border-white/[.1]">
				<div className="container mx-auto w-full flex flex-col px-4 py-16">
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
						<div className="card bg-base-200">
							<div className="card-body">
								<div className="flex">
									<span className="material-icons-outlined text-primary mr-2">
										groups
									</span>
									<h3 className="uppercase font-bold">Build a community</h3>
								</div>
								<div className="divider my-1"></div>
								<p>
									With communities you can build a following of drivers. Create
									championships and events within the community and keep track of
									current and previous races. Choose your own cover image and add
									your own website or social media accounts to expand your
									external following.
								</p>
							</div>
						</div>
						<div className="card bg-base-200">
							<div className="card-body">
								<div className="flex">
									<span className="material-icons-outlined text-primary mr-2">
										emoji_events
									</span>
									<h3 className="uppercase font-bold">
										Manage your championships
									</h3>
								</div>
								<div className="divider my-1"></div>
								<p>
									Create championships made up of multiple single events and
									include your own rules, scoring system and league table. Set the
									game, platform, tracks and cars to suit your needs. Add your own
									cover image to appeal to your community.
								</p>
							</div>
						</div>
						<div className="card bg-base-200">
							<div className="card-body">
								<div className="flex">
									<span className="material-icons-outlined text-primary mr-2">
										calendar_month
									</span>
									<h3 className="uppercase font-bold">Create events</h3>
								</div>
								<div className="divider my-1"></div>
								<p>
									As well as full blown championships; single events can also be
									created. Select the game, platform and track and which cars you
									will allow to enter. Choose your lap count or duration and how
									many sessions will be included, for example; practice,
									qualification and race.
								</p>
							</div>
						</div>
						<div className="card bg-base-200">
							<div className="card-body">
								<div className="flex">
									<span className="material-icons-outlined text-primary mr-2">
										bar_chart
									</span>
									<h3 className="uppercase font-bold">Track your stats</h3>
								</div>
								<div className="divider my-1"></div>
								<p>
									As well as tracking championship and event stats, you can view
									individual driver stats.
								</p>
								<p>
									Everything from race counts, to podium
									finishes and incidents and disqualifications; all laid out in an
									easy to follow design.
								</p>
							</div>
						</div>
						<div className="card bg-base-200">
							<div className="card-body">
								<div className="flex">
									<span className="material-icons-outlined text-primary mr-2">
										sports_esports
									</span>
									<h3 className="uppercase font-bold">Display your gear</h3>
								</div>
								<div className="divider my-1"></div>
								<p>
									Show off your setup by adding in your gear. Everything from your
									rig to your driving gloves; let other drivers know what’s under
									the hood.
								</p>
							</div>
						</div>
						<div className="card bg-base-200">
							<div className="card-body">
								<div className="flex">
									<span className="material-icons-outlined text-primary mr-2">
										flag
									</span>
									<h3 className="uppercase font-bold">Esports ready</h3>
								</div>
								<div className="divider my-1"></div>
								<p>
									Let Only Racing take the hassle out of managing your esports
									event with the ablity to create private championships and events
									with the ability to send out private invites to drivers.
								</p>
							</div>
						</div>
					</div>
					<div className="flex justify-center">
						<a href={route('register')} className="btn btn-primary lg:max-w-[70%]">
							Create an account now!
						</a>
					</div>
				</div>
			</section>

			<section className="container mx-auto px-4 my-12">
				<div className="divider mb-12">
					<h2>New Communities</h2>
				</div>
				<div className="flex w-full grid grid-cols-1 md:grid-cols-2 lg:md:grid-cols-3 2xl:grid-cols-4 gap-6 mb-8">
					{communities.map((community) => (
						<CommunityCard key={`community-${community.id}`} community={community} />
					))}
				</div>
				<div className="flex justify-center">
					<Link className={`btn btn-sm btn-ghost`} href={route('communities')}>
						<span>View all communities</span>
					</Link>
				</div>
			</section>
			<section className="container mx-auto px-4 my-12">
				<div className="divider mb-12">
					<h2>Posts</h2>
				</div>
				<div className="flex w-full grid grid-cols-1 md:grid-cols-2 lg:md:grid-cols-3 2xl:grid-cols-4 gap-6 mb-8">
					{posts?.map((blog) => (
						<PostCard key={blog.id} blog={blog} />
					))}
				</div>
				<div className="flex justify-center">
					<Link className={`btn btn-sm btn-ghost`} href={route('blogs.index')}>
						<span>View all posts</span>
					</Link>
				</div>
			</section>
		</>
	);
};

Home.layout = (page) => <MainLayout children={page} />;

export default Home;
